import React, { useEffect } from 'react';

import TransitionPage from 'src/components/shared/TransitionPage';
import { navigate } from 'gatsby';

const Favorites = () => {
    useEffect(() => {
        navigate('/favorites');
    }, []);

    return <TransitionPage />;
};

export default Favorites;
